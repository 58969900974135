import React, { useState, useCallback } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Row from "react-bootstrap/Row";
import "../FAQs/faq.css";

function CustomToggle({ children, eventKey, actionOnClick, iscurrent }) {
  const [key, setKey] = useState(null);
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    console.log("totally custom!", eventKey);

    actionOnClick(eventKey);
  });

  //   const isCurrent = actionOnClick() === eventKey;

  return <div onClick={decoratedOnClick}>{children}</div>;
}

const FAQ = () => {
  const [key, setKey] = useState(null);
  const [sameKey, setSameKey] = useState(false);
  const actionOnClick = (key1) => {
    if (key === key1) {
      setSameKey((prev) => !prev);
    } else {
      setSameKey(false);
    }
    setKey(key1);
  };
  return (
    <>
      <Row id="faq" className="mb-5 mx-3" style={{ paddingTop: "60px" }}>
        <p className="faq_heading_main">
          About <span style={{ color: "#475bd8" }}>Us</span>
        </p>
        <p className="mb-3">
        Welcome to Ingenium Education, a pioneering SaaS platform dedicated to empowering teacher educators and instructors in their transition from traditional offline teaching to the dynamic realm of online education. At Ingenium Education, we specialize in providing innovative white-labeled apps designed to facilitate seamless digital growth, enabling educators to expand their reach, enhance teaching methodologies, and diversify revenue streams.

<br /><br />With a steadfast commitment to revolutionizing the educational landscape, we have successfully supported over 10,000 teachers on their transformative journey towards digitization. Our platform serves as a catalyst for educators seeking to embrace the digital era, equipping them with the tools and resources necessary to thrive in an evolving educational environment.

<br /><br />Our mission is to empower educators worldwide, offering comprehensive solutions that cater to individual needs while fostering a community of learning and innovation. Through our user-friendly and customizable apps, we aim to bridge the gap between offline and online education, fostering a smooth transition and amplifying educational impact.

<br /><br />At Ingenium Education, we believe in the potential of educators to inspire, educate, and shape the future. Our relentless pursuit of excellence is driven by a passion for revolutionizing education, enabling educators to unlock new possibilities and create meaningful learning experiences for their students.

<br /><br />Join us in this transformative journey as we reimagine education together, empowering educators to embrace the digital frontier and unlock their true potential.
        </p>
      </Row>
      
    </>
  );
};

export default FAQ;
