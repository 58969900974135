import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Row from "react-bootstrap/Row";
import "../Footer/footer.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    document.getElementById("ppp").innerHTML = `
    <section class="section-padding">
            <h2 id='refund'>Refund Policy</h2>
            <p>All sales are final, and the Company does not offer any money-back guarantees.
            You recognize and agree that you shall not be entitled to a refund for any purchase under any circumstances.</p>
            <h4>Warranty Policy</h4>
            <p>THE COMPANY MAKES NO WARRANTIES REGARDING THE PERFORMANCE OR OPERATION OF THE PRODUCT/SERVICES. 
            THE COMPANY FURTHER MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE INFORMATION, CONTENTS, MATERIALS, DOCUMENTS, PROGRAMS, PRODUCTS, BOOKS, OR SERVICES INCLUDED ON OR THROUGH THE PRODUCT/S.
            TO THE FULLEST EXTENT PERMISSIBLE UNDER THE LAW, THE COMPANY DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING IMPLIED WARRANTIES OF MERCHANTABILITY AND FITNESS FOR A PARTICULAR PURPOSE.</p>
            <h4>Limitation of Liability </h4>
            <p>YOU AGREE TO ABSOLVE THE COMPANY OF ANY AND ALL LIABILITY OR LOSS THAT YOU OR ANY PERSON OR ENTITY ASSOCIATED WITH YOU MAY SUFFER OR INCUR AS A RESULT OF USE OF THE INFORMATION CONTAINED IN THE PRODUCT/SERVICES. 
            YOU AGREE THAT THE COMPANY SHALL NOT BE LIABLE TO YOU FOR ANY TYPE OF DAMAGES, INCLUDING DIRECT, INDIRECT, SPECIAL, INCIDENTAL, EQUITABLE, OR CONSEQUENTIAL LOSS OR DAMAGES FOR USE OF THIS PRODUCT/SERVICE. 
            THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE PRODUCT/SERVICE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. 
            CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. THE COMPANY AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE PRODUCT AT ANY TIME. 
            THE COMPANY AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE WEBSITE FOR ANY PURPOSE. 
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND.</p>
        </div>
    </section>`;
  }, []);
  return (
    <>
      <Navbar scrolled />
      <div style={{ marginTop: "120px" }} className="mx-2" id="ppp"></div>
      <Row className="footer_copyright">
        <p className="copyright_text">
          <strong>Copyright © Ingenium | 2022</strong>
        </p>
      </Row>
    </>
  );
};

export default PrivacyPolicy;
