import React, { useEffect } from "react";
import Navbar from "../Navbar/Navbar";
import Row from "react-bootstrap/Row";
import "../Footer/footer.css";

const PrivacyPolicy = () => {
  useEffect(() => {
    document.getElementById("ppp").innerHTML = `
    <section class="section-padding">
        <div class="container mt-5">
            <h1>Privacy Policy</h1>
            <p>Effective date: June 21, 2018</p>
            <p>Ingeinum (&quot;us&quot;, &quot;we&quot;, or &quot;our&quot;) operates the website and the Ingeinum mobile application (the &quot;Service&quot;).</p>
            <p>This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.</p>
            <p>We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.</p>
            <h2>Information Collection And Use</h2>
            <p>We collect several different types of information for various purposes to provide and improve our Service to you.</p>
            <h3>Types of Data Collected</h3>
            <h4>Personal Data</h4>
            <p>While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you (&quot;Personal Data&quot;). Personally identifiable information may include, but is not limited to:</p>
            <ul>
                <li>Email address</li>
                <li>First name and last name</li>
                <li>Phone number</li>
                <li>Address, State, Province, ZIP/Postal code, City</li>
                <li>Cookies and Usage Data</li>
            </ul>

            <h4>Your Media:</h4>
            <p>We may also collect and upload information about your media such as images, videos, audio, gifs, or documents in our various features available on the application. Some of the major features which will require you media information are as follows:</p>
            <ul>
                <li>Profile - The profile section will upload images that will be visible to other users to identify yourself.</li>
                <li>Display Page - This is a display page created by you to showcase your institute's portfolio, it can include multiple types of media files.</li>
                <li>Course - The courses created on the platform will upload audio and video files along with documents which will be visible to anyone who subscribes to the specific course on the platform.</li>
                <li>Chat - This communication feature on the application will collect media information such as audio files, video files, documents, gifs, etc to provide different methods to communicate.</li>
                <li>Library - This is a storage provided to store folder-wise content which can be shared with other users</li>
                <li>Assignments - Assignments will upload media such as question/solution images or videos which will be available to users with whom the assignments have been shared.</li>
            </ul>

            <h4>Usage Data</h4>
            <p>We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device (&quot;Usage Data&quot;).</p>
            <p>This Usage Data may include information such as your computer&apos;s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.</p>
            <p>When you access the Service by or through a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.</p>
            <h4>Your Connections (Contacts):</h4>
            <p>You can use the contact upload feature and provide us, if permitted by applicable laws, with the phone numbers in your address book on a regular basis, including those of users of our Services and your other contacts. If any of your contacts aren’t yet using our Services, we’ll manage this information for you in a way that ensures those contacts cannot be identified by us. This is done in order for you to add the user directly on your application without manually adding it.</p>
            <h3>Tracking &amp; Cookies Data</h3>
            <p>We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.</p>
            <p>Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.</p>
            <p>You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.</p>
            <p>Examples of Cookies we use:</p>
            <ul>
                <li><strong>Session Cookies.</strong>&nbsp;We use Session Cookies to operate our Service.</li>
                <li><strong>Preference Cookies.</strong>&nbsp;We use Preference Cookies to remember your preferences and various settings.</li>
                <li><strong>Security Cookies.</strong>&nbsp;We use Security Cookies for security purposes.</li>
            </ul>
            <h2>Use of Data</h2>
            <p>Ingeinum uses the collected data for various purposes:</p>
            <ul>
                <li>To provide and maintain the Service</li>
                <li>To notify you about changes to our Service</li>
                <li>To allow you to participate in interactive features of our Service when you choose to do so</li>
                <li>To provide customer care and support</li>
                <li>To provide analysis or valuable information so that we can improve the Service</li>
                <li>To monitor the usage of the Service</li>
                <li>To detect, prevent and address technical issues</li>
            </ul>
            <h2>Transfer Of Data</h2>
            <p>Your information, including Personal Data, may be transferred to &mdash; and maintained on &mdash; computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.</p>
            <p>If you are located outside India and choose to provide information to us, please note that we transfer the data, including Personal Data, to India and process it there.</p>
            <p>Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.</p>
            <p>Ingeinum will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.</p>
            <h2>Disclosure Of Data</h2>
            <h3>Legal Requirements</h3>
            <p>Ingeinum may disclose your Personal Data in the good faith belief that such action is necessary to:</p>
            <ul>
                <li>To comply with a legal obligation</li>
                <li>To protect and defend the rights or property of Ingeinum</li>
                <li>To prevent or investigate possible wrongdoing in connection with the Service</li>
                <li>To protect the personal safety of users of the Service or the public</li>
                <li>To protect against legal liability</li>
            </ul>
            <h2>Security Of Data</h2>
            <p>The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.</p>
            <h2>Service Providers</h2>
            <p>We may employ third party companies and individuals to facilitate our Service (&quot;Service Providers&quot;), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.</p>
            <p>These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.</p>
            <h2>Links To Other Sites</h2>
            <p>Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party&apos;s site. We strongly advise you to review the Privacy Policy of every site you visit.</p>
            <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.</p>
            <h2 id='refund'>Refund Policy</h2>
            <p>All sales are final, and the Company does not offer any money-back guarantees.
            You recognize and agree that you shall not be entitled to a refund for any purchase under any circumstances.</p>
            <h2>Limitation of Liability </h2>
            <p>YOU AGREE TO ABSOLVE THE COMPANY OF ANY AND ALL LIABILITY OR LOSS THAT YOU OR ANY PERSON OR ENTITY ASSOCIATED WITH YOU MAY SUFFER OR INCUR AS A RESULT OF USE OF THE INFORMATION CONTAINED IN THE PRODUCT/SERVICES. 
            YOU AGREE THAT THE COMPANY SHALL NOT BE LIABLE TO YOU FOR ANY TYPE OF DAMAGES, INCLUDING DIRECT, INDIRECT, SPECIAL, INCIDENTAL, EQUITABLE, OR CONSEQUENTIAL LOSS OR DAMAGES FOR USE OF THIS PRODUCT/SERVICE. 
            THE INFORMATION, SOFTWARE, PRODUCTS, AND SERVICES INCLUDED IN OR AVAILABLE THROUGH THE PRODUCT/SERVICE MAY INCLUDE INACCURACIES OR TYPOGRAPHICAL ERRORS. 
            CHANGES ARE PERIODICALLY ADDED TO THE INFORMATION HEREIN. THE COMPANY AND/OR ITS SUPPLIERS MAY MAKE IMPROVEMENTS AND/OR CHANGES IN THE PRODUCT AT ANY TIME. 
            THE COMPANY AND/OR ITS SUPPLIERS MAKE NO REPRESENTATIONS ABOUT THE SUITABILITY, RELIABILITY, AVAILABILITY, TIMELINESS, AND ACCURACY OF THE INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS CONTAINED ON THE WEBSITE FOR ANY PURPOSE. 
            TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, ALL SUCH INFORMATION, SOFTWARE, PRODUCTS, SERVICES AND RELATED GRAPHICS ARE PROVIDED "AS IS" WITHOUT WARRANTY OR CONDITION OF ANY KIND.</p>
            <h2>Changes To This Privacy Policy</h2>
            <p>We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.
            We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the &quot;effective date&quot; at the top of this Privacy Policy.
            You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>
            <h2>Contact Us</h2>
            <p>If you have any questions about this Privacy Policy, please contact us:</p>
            <ul>
                <li>By email: info@ingeniumedu.com</li>
                <li>By visiting this page on our website:&nbsp;<a href="https://ingeniumedu.com/">www.ingeniumedu.com</a></li>
                <li>By phone number: 8826286002</li>
            </ul>
        </div>
    </section>`;
  }, []);
  return (
    <>
      <Navbar scrolled />
      <div style={{ marginTop: "120px" }} id="ppp"></div>
      <Row className="footer_copyright">
        <p className="copyright_text">
          <strong>Copyright © Ingenium | 2022</strong>
        </p>
      </Row>
    </>
  );
};

export default PrivacyPolicy;
