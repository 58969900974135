import React, { useState, useCallback } from "react";
import Accordion from "react-bootstrap/Accordion";
import { useAccordionButton } from "react-bootstrap/AccordionButton";
import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./faq.css";

function CustomToggle({ children, eventKey, actionOnClick, iscurrent }) {
  const [key, setKey] = useState(null);
  const decoratedOnClick = useAccordionButton(eventKey, () => {
    console.log("totally custom!", eventKey);

    actionOnClick(eventKey);
  });

  //   const isCurrent = actionOnClick() === eventKey;

  return <div onClick={decoratedOnClick}>{children}</div>;
}

const FAQ = () => {
  const [key, setKey] = useState(null);
  const [sameKey, setSameKey] = useState(false);
  const actionOnClick = (key1) => {
    if (key === key1) {
      setSameKey((prev) => !prev);
    } else {
      setSameKey(false);
    }
    setKey(key1);
  };
  return (
    <>
      <Row id="faq" style={{ paddingTop: "120px" }}>
        <p className="faq_heading_main">
          Frequently Asked <span style={{ color: "#475bd8" }}>Questions</span>
        </p>
      </Row>
      <Accordion style={{ paddingBottom: "120px" }} defaultActiveKey="10">
        {/* <Card style={{borderBottom: '1px solid black'}}> */}
        {/* <CustomToggle
          actionOnClick={actionOnClick}
          iscurrent={key === "0"}
          eventKey="0"
          style={{backgroundColor: 'white'}}
        >
          <div
            onCLick={() => actionOnClick("0")}
            style={{
              width: "98%",
              backgroundColor: 'rgb(255,255,255)',
              color: key === "0" && !sameKey ? "#475bd8" : "black",
            }}
            className="d-flex align-items-center justify-content-between ques"
          >
            <p className="faq_question" type="button">
              What is the price of the mobile app?
            </p>
            {key === "0" && !sameKey ? (
              <ion-icon name="remove-circle-outline"></ion-icon>
            ) : (
              <ion-icon name="add-circle-outline"></ion-icon>
            )}
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey="0">
          <Card.Body className="faq_ans">
            We have two plans: Basic & Premium. For the Basic Plan, you have to
            pay INR 999 per year. For the premium plan, the costing is INR 999
            per quarter, i.e. INR 3996/- per year.
          </Card.Body>
        </Accordion.Collapse>
        <hr style={{ width: "97%", margin: "auto" }} /> */}
        {/* </Card> */}

        {/* <CustomToggle
          iscurrent={key === "1"}
          actionOnClick={actionOnClick}
          eventKey="1"
        >
          <div
            onCLick={() => actionOnClick("1")}
            style={{
              width: "98%",
              color: key === "1" && !sameKey ? "#475bd8" : "black",
            }}
            className="d-flex align-items-center justify-content-between ques"
          >
            <p className="faq_question" type="button">
              What is the difference between Basic & Premium plans?
            </p>
            {key === "1" && !sameKey ? (
              <ion-icon name="remove-circle-outline"></ion-icon>
            ) : (
              <ion-icon name="add-circle-outline"></ion-icon>
            )}
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey="1">
          <Card.Body className="faq_ans">
            Basic Plan: We integrate your Zoom or Google Meet account into the
            app. Premium Plan: You can conduct live classes with our advanced
            live classes software integrated within the app. There will be no
            time-limit in the live class.
          </Card.Body>
        </Accordion.Collapse>
        <hr style={{ width: "97%", margin: "auto" }} /> */}

        <CustomToggle
          iscurrent={key === "2"}
          actionOnClick={actionOnClick}
          eventKey="2"
        >
          <div
            onCLick={() => actionOnClick("2")}
            style={{
              width: "98%",
              color: key === "2" && !sameKey ? "#475bd8" : "black",
            }}
            className="d-flex align-items-center justify-content-between ques"
          >
            <p className="faq_question" type="button">
              Will this be an Android app or iOS too?
            </p>
            {key === "2" && !sameKey ? (
              <ion-icon name="remove-circle-outline"></ion-icon>
            ) : (
              <ion-icon name="add-circle-outline"></ion-icon>
            )}
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey="2">
          <Card.Body className="faq_ans">
            For now, we only create Android app for the teachers & coaching
            institutes. Your app will be published on Playstore by us.
          </Card.Body>
        </Accordion.Collapse>
        <hr style={{ width: "97%", margin: "auto" }} />

        <CustomToggle
          iscurrent={key === "3"}
          actionOnClick={actionOnClick}
          eventKey="3"
        >
          <div
            onCLick={() => actionOnClick("3")}
            style={{
              width: "98%",
              color: key === "3" && !sameKey ? "#475bd8" : "black",
            }}
            className="d-flex align-items-center justify-content-between ques"
          >
            <p className="faq_question" type="button">
              Will my app have my name & logo on Playstore?
            </p>
            {key === "3" && !sameKey ? (
              <ion-icon name="remove-circle-outline"></ion-icon>
            ) : (
              <ion-icon name="add-circle-outline"></ion-icon>
            )}
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey="3">
          <Card.Body className="faq_ans">
            Yes. We'll create a mobile app for your brand, with your logo & name
            and publish it on Playstore. All the branding will be yours only.
          </Card.Body>
        </Accordion.Collapse>
        <hr style={{ width: "97%", margin: "auto" }} />

        <CustomToggle
          iscurrent={key === "4"}
          actionOnClick={actionOnClick}
          eventKey="4"
        >
          <div
            onCLick={() => actionOnClick("4")}
            style={{
              width: "98%",
              color: key === "4" && !sameKey ? "#475bd8" : "black",
            }}
            className="d-flex align-items-center justify-content-between ques"
          >
            <p className="faq_question" type="button">
              What are the payment terms?{" "}
            </p>
            {key === "4" && !sameKey ? (
              <ion-icon name="remove-circle-outline"></ion-icon>
            ) : (
              <ion-icon name="add-circle-outline"></ion-icon>
            )}
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey="4">
          <Card.Body className="faq_ans">
            You can start our services & publish your own app only by paying INR
            4999. 
          </Card.Body>
        </Accordion.Collapse>
        <hr style={{ width: "97%", margin: "auto" }} />

        <CustomToggle
          iscurrent={key === "5"}
          actionOnClick={actionOnClick}
          eventKey="5"
        >
          <div
            onCLick={() => actionOnClick("5")}
            style={{
              width: "98%",
              color: key === "5" && !sameKey ? "#475bd8" : "black",
            }}
            className="d-flex align-items-center justify-content-between ques"
          >
            <p className="faq_question" type="button">
              Can I run my art, music or yoga classes on this app?
            </p>
            {key === "5" && !sameKey ? (
              <ion-icon name="remove-circle-outline"></ion-icon>
            ) : (
              <ion-icon name="add-circle-outline"></ion-icon>
            )}
          </div>
        </CustomToggle>
        <Accordion.Collapse eventKey="5">
          <Card.Body className="faq_ans">
            Definitely. Whether you're an academic teacher, competition coach,
            fitness instructor, music teacher, dance instructor, art teacher,
            yoga teacher, food instructor, or else, you can conduct online
            session for your students.
          </Card.Body>
        </Accordion.Collapse>
        <hr style={{ width: "97%", margin: "auto" }} />
      </Accordion>
    </>
  );
};

export default FAQ;
