import React, { useState, useEffect } from "react";
import whiteLogo from "../../assets/WhiteLogo.png";
import classes from "./navbar.module.css";

const Navbar = (props) => {
  const [scrolled, setScrolled] = useState(props.scrolled || false);
  const [navState, setNavState] = useState(false);
  useEffect(() => {
    window.onscroll = () => {
      if (document.documentElement.scrollTop > 40) {
        setScrolled(true);
      } else {
        setScrolled(props.scrolled || false);
      }
    };
  }, []);

  const changeNavState = () => {
    setNavState((prev) => !prev);
  };

  const scrollToSection = (id) => {
    document.getElementById(id).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className={scrolled ? classes.navbarScrolled : classes.navbar}>
      <img
        className={scrolled ? classes.logoImageScrolled : classes.logoImage}
        alt="logo"
        src={whiteLogo}
      />

      <ul
        style={{ margin: scrolled ? "auto" : "0px" }}
        className={classes.navlist}
      >
        <li onClick={() => props.scrolled ? {} : scrollToSection("top")}>
       {props.scrolled ? <a href='https://www.ingeniumedu.com/' style={{textDecoration: 'none', color: 'white'}}>Home</a> : "Home"}
        </li>
        <li onClick={() => scrollToSection("features")}>Features</li>
        <li onClick={() => scrollToSection("faq")}>FAQs</li>
      </ul>
      <p className={scrolled ? classes.navmobileScrolled : classes.navmobile}>
        <ion-icon name="call"></ion-icon>

        <a
          style={{ textDecoration: "none", color: "#fff" }}
          href="tel:8826286002"
        >
          +91-8826286002
        </a>
      </p>

      <div onClick={changeNavState} className={classes.mobilenavicon}>
        <ion-icon name="reorder-three-outline"></ion-icon>
      </div>

      <div
        style={navState ? { opacity: "1" } : { opacity: "0" }}
        className={
          navState
            ? [classes.mobnavcontainer, classes.withHeight].join(" ")
            : classes.mobnavcontainer
        }
      >
        <ul>
          <li onClick={() => props.scrolled ? {} : scrollToSection("top")}>
       {props.scrolled ? <a href='https://www.ingeniumedu.com/' style={{textDecoration: 'none', color: 'white'}}>Home</a> : "Home"}
        </li>
          <li onClick={() => scrollToSection("features")}>Features</li>
          <li onClick={() => scrollToSection("faq")}>FAQs</li>
          <li>
            <a
              style={{ textDecoration: "none", color: "#fff" }}
              href="tel:8826286002"
            >
              <ion-icon name="call"></ion-icon> +91-8826286002
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Navbar;
