import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import "./tabcomponent.css";

const Tabcomponent = () => {
  const [key, setKey] = useState("1");

  const goRight = () => {
    setKey((prev) => {
        if (prev == "8") return "1";
        return String(+prev + 1);
      });
  }

  const goLeft = () => {
    setKey((prev) => {
        if (prev == "1") return "8";
        return String(+prev - 1);
      });
  }

  useEffect(() => {
    const timer = setInterval(() => {
      setKey((prev) => {
        if (prev == "8") return "1";
        return String(+prev + 1);
      });
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className="tabs-container">
      <Tabs
        onSelect={(k) => setKey(k)}
        transition={false}
        defaultActiveKey="1"
        activeKey={key}
        className="mb-3 tabs"
      >
        <Tab className="tab" eventKey="1" title="1">
          <h3 className="tab_heading">Personalized Apps</h3>
          <p className="tab_text">
            To boost your brand's name, build your customized app and web
            portal.
          </p>
        </Tab>
        <Tab className="tab" eventKey="2" title="2">
          <h3 className="tab_heading">Live Classes</h3>
          <p className="tab_text">
            Conduct Live classes, Record Live lectures, Use Digital Whiteboards.
            You See your Students and They See You.
          </p>
        </Tab>
        <Tab className="tab" eventKey="3" title="3">
          <h3 className="tab_heading">Smart Attendance</h3>
          <p className="tab_text">
            Mark Attendance of Your Online and Offline classes. Notify Parents
            via SMS.
          </p>
        </Tab>
        <Tab className="tab" eventKey="4" title="4">
          <h3 className="tab_heading">Fee Management</h3>
          <p className="tab_text">
            Free yourself from payments hassle. Students can pay online on their
            app. Be it a monthly fee or any kind of installments.
          </p>
        </Tab>
        <Tab className="tab" eventKey="5" title="5">
          <h3 className="tab_heading">Upload Study Material</h3>
          <p className="tab_text">
            Provide access to Notes, Documents, or any kind of study material to
            your students. Let them use your app and study on their own.
          </p>
        </Tab>
        <Tab className="tab" eventKey="6" title="6">
          <h3 className="tab_heading">Online Tests</h3>
          <p className="tab_text">
            Create Tests and Assignments for students. Get all the tests to
            check automatically for you.
          </p>
        </Tab>
        <Tab className="tab" eventKey="7" title="7">
          <h3 className="tab_heading">Sell Your Courses</h3>
          <p className="tab_text">
            Create Your Own videos courses and test series. Sell them anywhere
            and get paid directly to your Bank Account.
          </p>
        </Tab>
        <Tab className="tab" eventKey="8" title="8">
          <h3 className="tab_heading">Student Reports</h3>
          <p className="tab_text">
            Detailed Reports of students on your app. Generate a performance
            report and share it with parents with a single click.
          </p>
        </Tab>
        <div className='d-flex arrows'>
          <ion-icon onClick={goLeft} name="caret-back-circle-outline"></ion-icon>
          <ion-icon onClick={goRight} name="caret-forward-circle-outline"></ion-icon>
        </div>
      </Tabs>
    </div>
  );
};

export default Tabcomponent;
