import React, { useState } from "react";
import classes from "./testimonials.module.css";
import classes2 from "../Numbers/numbers.module.css";
// import Carousel from "react-bootstrap/Carousel";
// import { Row, Col } from "react-bootstrap";
import Owlcarousel from "../Owlcaraousel/Owlcarousel";
import CustomModal from "../Modal/Modal";

const Testimonials = () => {
  const [showModal, setShowModal] = useState(false);

  const hideModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };
  return (
    <section className={classes.testimonials}>
      {/* <div className={classes.overlay}></div> */}
      <div className={classes.testimonialsMainDiv}>
        <div className={classes.testimonial_content}>
          <div className={classes.testimonials_left}>
            <p className={classes.midMaintext}>
              Create your <span className={classes.yellow}>own app,</span>
              <br />
              <span className={classes.yellow}>Make your</span> own brand.
            </p>
            <p style={{ fontSize: "24px", fontWeight: "800", color: "#fff" }}>
              Get the android app and web portal by your brand's name
            </p>
            <button onClick={openModal} className={classes2.createMyAppButton}>
              Create my own app
            </button>
          </div>
        </div>

        <Owlcarousel />
      </div>

      <CustomModal show={showModal} hideModal={hideModal} />
    </section>
  );
};

export default Testimonials;
