import React, { useState, useEffect, useRef } from "react";
import Navbar from "../Navbar/Navbar";
import Header from "../Header/Header";
import Numbers from "../Numbers/Numbers";
import Testimonials from "../Testimonial/Testimonials";
import VideoSection from "../VideoSection/VideoSection";
import FAQ from "../FAQs/FAQ";
import FormSection from "../FormSection/FormSection";
import About from "../AboutUs/About";
import Footer from "../Footer/Footer";
import "./main.css";
import axios from "axios";

const Main = () => {
  const gototop = () => {
    document.getElementById("top").scrollIntoView({ behavior: "smooth" });
  };

  async function Locationofip(){
    const ipj = await fetch("https://api.ipify.org/");
    const ip = await ipj.text();
    const resp = await fetch(`"https://get.geojs.io/v1/ip/geo/${ip}.json"`);
    const dat = await resp.json();
    console.log(dat)
    return ip;
  }

  useEffect(() => {
     Locationofip()

    let key = localStorage.getItem("Device_id");
    console.log(key, "Key is this");
    if (key === null || !key) {
      console.log("In possibility");
      let y = (Math.random() + 1).toString(16).substring(1);
      let ke = Date.now();
      key = `${ke}*${y}`;
      localStorage.setItem("Device_id", key);
      const data = {
        time: ke,
        token: key,
      };
      axios
        .post("https://class.ingeniumedu.com/enquiryOnLanding", data)
        .then(() => {
          localStorage.setItem("Device_id", key);
        });
    } else {
      console.log("Denial");
      const date = new Date(Date.now());
      const today = `${date.getDate()}-${date.getMonth()}-${date.getFullYear()}`;
      let keypartition = key.split("*");
      console.log(keypartition)
      const keydate = new Date(Number(keypartition[0]));
      const keyday = `${keydate.getDate()}-${keydate.getMonth()}-${keydate.getFullYear()}`;
      console.log(keyday,today)
      if (keyday !== today) {
        let ke = Date.now();
        const data = {
          time: ke,
          token: key,
        };
        key = `${ke}*${keypartition[1]}`;
        axios.post("https://class.ingeniumedu.com/enquiryOnLanding", data);
        // fetch("https://class.ingeniumedu.com/enquiryOnLanding", {
        //   method: "POST",
        //   body: JSON.stringify(data),
        // }).then(result=> result.json())
        // .then((resp)=>{
        //   console.log(resp)
        //   localStorage.setItem("Device_id", key);
        // });
      }
      else{
        console.log("No Api Called")
      }
    }
  }, []);

  return (
    <div>
      <Navbar />
      <Header />
      <About />
      <Numbers />
      <Testimonials />
      <VideoSection />
      <FAQ />
      <FormSection />
      <Footer />

      <button onClick={gototop} className="topArrow">
        <ion-icon name="caret-up"></ion-icon>
      </button>
    </div>
  );
};

export default Main;
