import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
import iElement from "../../assets/images/iiiii.svg";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Alert from "react-bootstrap/Alert";
import axios from "axios";
import validator from "validator";
import "./formsection.css";

const FormSection = () => {
  const [instituteName, setInstituteName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [postSuccess, setPostSuccess] = useState(0);
  const [posted, setPosted] = useState(false);
  const [validationText, setValidationText] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");

  const prospectID = window.MXCProspectId || "test";
  const postLead = () => {
    const payload = [
      {
        Attribute: "FirstName",
        Value: instituteName,
      },
      {
        Attribute: "LastName",
        Value: "",
      },
      {
        Attribute: "EmailAddress",
        Value: email,
      },
      {
        Attribute: "Phone",
        Value: contact,
      },
      {
        Attribute: "ProspectID",
        Value: prospectID,
      },
      {
        Attribute: "SearchBy",
        Value: "ingeniumedu",
      },
    ];

    console.log(payload);
    if (!(isValid("name") && isValid("email") && isValid("contact"))) {
      setValidationText(true);
      return;
    }

    const key = localStorage.getItem("Device_id");

    axios
      .post("https://class.ingeniumedu.com/enquiryOnLandiangbyDemo", {
        token: key,
        time: Date.now(),
        address:"Delhi"
      })
      .then((res) => {
        console.log(res);
      });

    axios
      .post(
        "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r3071f7b7d781e327e5a64aa9180854e7&secretKey=915a2ecc16209be957335a402b921038f1c65951",
        payload
      )
      .then((res) => {
        setPosted(true);
        setPostSuccess(1);
        setEmail("");
        setContact("");
        setInstituteName("");
        setAlertMsg(
          "We have received your request. We will get back to you shortly."
        );
        console.log(res);
      })
      .catch(function (error) {
        setPosted(true);
        setPostSuccess(0);
        setEmail("");
        setContact("");
        setInstituteName("");
        setAlertMsg(
          "Oops! Looks like something is wrong. Please try again after some time."
        );
        console.log(error);
      });
  };

  const onDismiss = () => {
    setPosted(false);
  };

  const isValid = (id) => {
    if (id === "email") {
      if (!email) {
        // setEmailValidationText('Email is a required field*');
        return false;
      } else {
        // validator.isEmail(email) ? setEmailValidationText('') : setEmailValidationText('Please enter a valid email address');
        return validator.isEmail(email);
      }
    } else if (id === "contact") {
      if (!contact) {
        // setContactValidationText('Contact is a required field*')
        return false;
      } else {
        // validator.isNumeric(contact) ? setContactValidationText('') : setContactValidationText('Please enter a valid contact')
        return validator.isNumeric(contact);
      }
    } else if (id === "name") {
      if (!instituteName) {
        // setInstituteNameValidationText('Institute Name is a required field*')
        return false;
      } else {
        return true;
      }
    }
  };
  return (
    <Row className="form_section">
      <Row className="form_section_div">
        <Row className="form_section_row">
          <Col md="7">
            <h2 className="form_heading">
              Mobile app for your{" "}
              <span style={{ color: "#fed063" }}>school & coaching class,</span>{" "}
              you and your student will love.
            </h2>
            <img src={iElement} alt="Ingenium Education" className="iElement" />
          </Col>

          <Col md="4">
            <div className="formDiv">
              <h3 className="formdiv_heading">Get Your Free Demo</h3>
              <p className="formdiv_subtext">
                Build your own coaching app and start teaching from anywhere in
                the world.
              </p>
              {posted && (
                <Alert
                  onClose={onDismiss}
                  variant={postSuccess ? "success" : "danger"}
                  dismissible
                >
                  {alertMsg}
                </Alert>
              )}
              <FloatingLabel
                controlId="floatingInput"
                label="Institute Name"
                className="mb-3 fl"
              >
                <Form.Control
                  type="text"
                  placeholder="Instiute Name"
                  onChange={(e) => setInstituteName(e.target.value)}
                  value={instituteName}
                  required
                />
                {validationText && !isValid("name") && (
                  <p className="mb-0 formalert">
                    {" "}
                    Please enter a valid Institute Name{" "}
                  </p>
                )}
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingPassword"
                className="mb-3 fl"
                label="Contact Number"
              >
                <Form.Control
                  type="tel"
                  placeholder="Contact Number"
                  onChange={(e) => setContact(e.target.value)}
                  value={contact}
                  required
                />
                {validationText && !isValid("contact") && (
                  <p className="mb-0 formalert">
                    {" "}
                    Please enter a valid contact number{" "}
                  </p>
                )}
              </FloatingLabel>

              <FloatingLabel
                controlId="floatingInput"
                label="Email address"
                className="mb-3 fl"
              >
                <Form.Control
                  type="email"
                  placeholder="Email address"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                  required
                />
                {validationText && !isValid("email") && (
                  <p className="mb-0 formalert">
                    {" "}
                    Please enter a valid Email address{" "}
                  </p>
                )}
              </FloatingLabel>

              <button onClick={postLead} className="getdemobtn">
                Get A Free Demo Now
              </button>

              <p className="formdiv_subtext">
                By continuing, you accept our Terms of Use and our Privacy
                Policy. You confirm that we may use your information to contact
                you in future.
              </p>
            </div>
          </Col>
        </Row>
      </Row>
    </Row>
  );
};

export default FormSection;
