import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
// import YouTube from "react-youtube";
// import PlyrComponent from "plyr-react";
// import "plyr-react/plyr.css";
import CustomModal from "../Modal/Modal";
import "./video.css";

const VideoSection = () => {
  const [showModal, setShowModal] = useState(false);

  const hideModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };

  const stars = [1, 2, 3, 4, 5].map((ele) => {
    return <ion-icon key={ele} name="star"></ion-icon>;
  });
  const source = {
    type: "video",
    sources: [
      {
        src: "57kpaAVeO3k",
        provider: "youtube",
      },
    ],
  };
  // https://youtu.be/57kpaAVeO3k?si=s6euOD3_NFPuYoNn
  const options = {
    autoplay: true,
    youtube: {
      noCookie: false,
      rel: 0,
      showinfo: 0,
      iv_load_policy: 3,
      modestbranding: 1,
    },
  };
  const opts = {
    height: window.screen.width > 500 ? "280" : "180",
    width: window.screen.width > 500 ? "490" : "320",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  return (
    <>
      <Row className="video_section padding px-3">
        <Col lg="6" className="left">
          <p className="video_heading">
            Trusted by thousands of Institutes and Individual teachers all
            around India
          </p>
          {stars}
          <p className="video_subHeading">
            4.6 average rating from 7000+ customers
          </p>
          <button onClick={openModal} className="video_createButton">
            Create my own app
          </button>
        </Col>
        <Col lg="6">
          <div className="plyrcomponent" style={{ textAlign: "center" }}>
            {/* <YouTube
              style={{ borderRadius: "5px" }}
              videoId="We--LtK3fNI"
              opts={opts}
            /> */}
            {/* <PlyrComponent source={source} options={options} /> */}
            <iframe width="100%" height="315"
            src="https://www.youtube.com/embed/57kpaAVeO3k">
            </iframe>
          </div>
        </Col>
      </Row>
      <Row className="video_footer py-5">
        <Col lg="8">
          <p className="video_footer_heading">
            Your name <span>your app</span>, your logo <span>your brand</span>{" "}
          </p>
        </Col>
        <Col lg="3" className="text-center">
          <button onClick={openModal} className="video_createBrand">
            Create My Brand
          </button>
        </Col>
      </Row>

      <CustomModal show={showModal} hideModal={hideModal} />
    </>
  );
};

export default VideoSection;
