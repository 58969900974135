import React from "react";
import { Routes, Route } from "react-router-dom";
import PrivacyPolicy from "./Components/PrivacyPolicy/PrivacyPolicy";
import Main from "./Components/Main/Main";
import Math10 from "./Components/Math10/Math10";
import "./App.css";
import Terms from "./Components/Terms/Terms";
import Refund from "./Components/Refund/Refund";

const App = () => {
  return (
    <div style={{ overflowX: "hidden" }}>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/enquiry" element={<Main />} />
        <Route path="/index2.html" element={<PrivacyPolicy />} />
        <Route path="/privacyPolicy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/refundpolicy" element={<Refund />} />
        {/* <Route path="/.well-known/assetlinks.json" element={<PrivacyPolicy />} /> */}
        
        {/* <Route path="/secret-santa-110122" element={<Math10 text='' />} /> */}
        {/* <Route path="/M10xm4v2" element={<Math10 text='Polynomials' />} />
        <Route path="/M10kxbIT" element={<Math10 text='Pair of Linear Equations' />} />
        <Route path="/M101I4gs" element={<Math10 text='Quadratic Equations' />} />
        <Route path="/M10OgvDN" element={<Math10 text='Arithemetic Progression' />} />
        <Route path="/M100V94O" element={<Math10 text='Co-ordinate Geometry' />} />
        <Route path="/M10VYUo5" element={<Math10 text='Triangles' />} />
        <Route path="/M106ATcJ" element={<Math10 text='Circles' />} />
        <Route path="/M10looIJ" element={<Math10 text='Contructions' />} />
        <Route path="/M10YGSv5" element={<Math10 text='Trigonometric Ratios' />} />
        <Route path="/M10qyc5G" element={<Math10 text='Trigonometric Identities' />} />
        <Route path="/M10sgxNb" element={<Math10 text='Heights and Distances' />} />
        <Route path="/M10aAYGx" element={<Math10 text='Areas Related to Circles' />} />
        <Route path="/M107dEGa" element={<Math10 text='Surface areas and Volumes' />} />
        <Route path="/M109waEC" element={<Math10 text='Statistics' />} />
        <Route path="/M1059nmX" element={<Math10 text='Probability' />} />
        <Route path="/M10hKq6L" element={<Math10 text='RD Sharma Class X' />} /> */}
      </Routes>
    </div>
  );
};

export default App;


// https://re-direct.ingeniumedu.com/M10uyxkW => ch1
// https://re-direct.ingeniumedu.com/M10xm4v2 => ch2
// https://re-direct.ingeniumedu.com/M10kxbIT => ch3
// https://re-direct.ingeniumedu.com/M101I4gs => ch4
// https://re-direct.ingeniumedu.com/M10OgvDN => ch5
// https://re-direct.ingeniumedu.com/M100V94O => ch6
// https://re-direct.ingeniumedu.com/M10VYUo5 => ch7
// https://re-direct.ingeniumedu.com/M106ATcJ => ch8
// https://re-direct.ingeniumedu.com/M10looIJ => ch9
// https://re-direct.ingeniumedu.com/M10YGSv5 => ch10
// https://re-direct.ingeniumedu.com/M10qyc5G => ch11
// https://re-direct.ingeniumedu.com/M10sgxNb => ch12
// https://re-direct.ingeniumedu.com/M10aAYGx => ch13
// https://re-direct.ingeniumedu.com/M107dEGa => ch14
// https://re-direct.ingeniumedu.com/M109waEC => ch15
// https://re-direct.ingeniumedu.com/M1059nmX => ch16
// https://re-direct.ingeniumedu.com/M10hKq6L => mainpage