import React from "react";
import classes from "./Owlcarousel.module.css";

const Mobile = (props) => {
  return (
    <div className={classes.mobile}>
      <div
        style={{
          height: "150px",
          overflow: "hidden",
          textAlign: "center",
        }}
      >
        <img
          style={{ borderRadius: "12px 12px 0px 0px" }}
          alt="testimonial"
          className={classes.clientimg}
          src={props.image}
        />
      </div>
      <div
        style={{ borderRadius: "0px 0px 12px 12px", minHeight: "215px" }}
        className={classes.quoteBox}
      >
        <blockquote className="p-2 m-0">{props.text}</blockquote>
        <p className={classes.personName}>{props.name}</p>
        <p className={classes.clientName}>{props.client}</p>
      </div>
    </div>
  );
};

export default Mobile;
