import React, { useState, useEffect } from "react";
import classes from "./header.module.css";
import logoSymbol from "../../assets/images/logo_symbol.webp";
import mobileImage from "../../assets/images/1.webp";
import CustomModal from "../Modal/Modal";
import axios from "axios";
import validator from "validator";
import { FloatingLabel, Form, Alert } from "react-bootstrap";
import "../FormSection/formsection.css";

const Header = (props) => {
  const [instituteName, setInstituteName] = useState("");
  const [email, setEmail] = useState("");
  const [contact, setContact] = useState("");
  const [postSuccess, setPostSuccess] = useState(0);
  const [posted, setPosted] = useState(false);
  const [validationText, setValidationText] = useState(false);
  const [alertMsg, setAlertMsg] = useState("");
  const prospectID = window.MXCProspectId || "test";
  const [showModal, setShowModal] = useState(false);

  const hideModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    setShowModal(true);
  }, [])

  const openModal = () => {
    setShowModal(true);
  };

  const postLead = () => {
    const payload = [
      {
        Attribute: "FirstName",
        Value: instituteName,
      },
      {
        Attribute: "LastName",
        Value: "",
      },
      {
        Attribute: "EmailAddress",
        Value: email,
      },
      {
        Attribute: "Phone",
        Value: contact,
      },
      {
        Attribute: "ProspectID",
        Value: prospectID,
      },
      {
        Attribute: "SearchBy",
        Value: "ingeniumedu",
      },
    ];

    console.log(payload);
    if (!(isValid("name") && isValid("email") && isValid("contact"))) {
      setValidationText(true);
      return;
    }

    axios
      .post(
        "https://api-in21.leadsquared.com/v2/LeadManagement.svc/Lead.Capture?accessKey=u$r3071f7b7d781e327e5a64aa9180854e7&secretKey=915a2ecc16209be957335a402b921038f1c65951",
        payload
      )
      .then((res) => {
        setPosted(true);
        setPostSuccess(1);
        setEmail("");
        setContact("");
        setInstituteName("");
        setAlertMsg(
          "We have received your request. We will get back to you shortly."
        );
        console.log(res);
      })
      .catch(function (error) {
        setPosted(true);
        setPostSuccess(0);
        setEmail("");
        setContact("");
        setInstituteName("");
        setAlertMsg(
          "Oops! Looks like something is wrong. Please try again after some time."
        );
        console.log(error);
      });
  };

  const onDismiss = () => {
    setPosted(false);
  };

  const isValid = (id) => {
    if (id === "email") {
      if (!email) {
        // setEmailValidationText('Email is a required field*');
        return false;
      } else {
        // validator.isEmail(email) ? setEmailValidationText('') : setEmailValidationText('Please enter a valid email address');
        return validator.isEmail(email);
      }
    } else if (id === "contact") {
      if (!contact) {
        // setContactValidationText('Contact is a required field*')
        return false;
      } else {
        // validator.isNumeric(contact) ? setContactValidationText('') : setContactValidationText('Please enter a valid contact')
        return validator.isNumeric(contact);
      }
    } else if (id === "name") {
      if (!instituteName) {
        // setInstituteNameValidationText('Institute Name is a required field*')
        return false;
      } else {
        return true;
      }
    }
  };

  const stars = [1, 2, 3, 4, 5].map((ele) => {
    return <ion-icon key={ele} name="star"></ion-icon>;
  });

  return (
    <div id="top" className={classes.header}>
      <div className={classes.logoSymbolImageDiv}>
        <img alt="ingenium" src={logoSymbol} />
      </div>
      <div className={classes.mobileImageDiv}>
        <img alt="ingenium" src={mobileImage} />
      </div>
      <div className={classes.leftEmpty}></div>
      <div className={classes.rightEmpty}>
        <div className={classes.centerTextDiv}>
          <p className={classes.smallText}>
            {stars} Trusted By Thousands Of Professionals
          </p>
          <p className={classes.centerTextMain}>
            Get Your Own <br />
            Coaching Institute App
            <br />
            <span style={{ color: "#fed063" }}>Starting @</span>
            <span className={classes.priceBox}>
              ₹ 4999 <span style={{ fontSize: "20.8px" }}></span>
            </span>
          </p>
          <button onClick={openModal} className={classes.freeDemoButton}>
            Get A Free Demo Now
          </button>
        </div>
        <div className={classes.verticalTextDiv}>
          <p>Ingenium</p>
        </div>
        <div className="formDiv showAfter608">
          <h3 className="formdiv_heading">Get Your Free Demo</h3>
          <p className="formdiv_subtext">
            Build your own coaching app and start teaching from anywhere in the
            world.
          </p>
          {posted && (
            <Alert
              onClose={onDismiss}
              variant={postSuccess ? "success" : "danger"}
              dismissible
            >
              {alertMsg}
            </Alert>
          )}
          <FloatingLabel
            controlId="floatingInput"
            label="Institute Name"
            className="mb-3 fl"
          >
            <Form.Control
              type="text"
              placeholder="Instiute Name"
              onChange={(e) => setInstituteName(e.target.value)}
              value={instituteName}
              required
            />
            {validationText && !isValid("name") && (
              <p className="mb-0 formalert">
                {" "}
                Please enter a valid Institute Name{" "}
              </p>
            )}
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingPassword"
            className="mb-3 fl"
            label="Contact Number"
          >
            <Form.Control
              type="tel"
              placeholder="Contact Number"
              onChange={(e) => setContact(e.target.value)}
              value={contact}
              required
            />
            {validationText && !isValid("contact") && (
              <p className="mb-0 formalert">
                {" "}
                Please enter a valid contact number{" "}
              </p>
            )}
          </FloatingLabel>

          <FloatingLabel
            controlId="floatingInput"
            label="Email address"
            className="mb-3 fl"
          >
            <Form.Control
              type="email"
              placeholder="Email address"
              onChange={(e) => setEmail(e.target.value)}
              value={email}
              required
            />
            {validationText && !isValid("email") && (
              <p className="mb-0 formalert">
                {" "}
                Please enter a valid Email address{" "}
              </p>
            )}
          </FloatingLabel>

          <button onClick={postLead} className="getdemobtn">
            Get A Free Demo Now
          </button>

          <p className="formdiv_subtext">
            By continuing, you accept our Terms of Use and our Privacy Policy.
            You confirm that we may use your information to contact you in
            future.
          </p>
        </div>
      </div>

      <CustomModal show={showModal} hideModal={hideModal} />
    </div>
  );
};

export default Header;
