import React, { useState } from "react";
import classes from "./numbers.module.css";
import girlPic from "../../assets/images/2_.webp";
import cube from "../../assets/images/bg-cube.svg";
import Button from "react-bootstrap/Button";
import Tabcomponent from "../Tabcomponent/Tabcomponent";
import { Row, Col } from "react-bootstrap";
import CustomModal from "../Modal/Modal";

const Numbers = (props) => {
  const [showModal, setShowModal] = useState(false);

  const hideModal = () => {
    setShowModal(false);
  };

  const openModal = () => {
    setShowModal(true);
  };
  return (
    <>
      <Row className={classes.numbersBox}>
        <Col className="my-3" lg="3" sm="6">
          <p className={classes.bigText}>7000+</p>
          <p className={classes.normalText}>Happy Teachers</p>
        </Col>
        <Col className="my-3" lg="3" sm="6">
          <p className={classes.bigText}>5 Lakh+</p>
          <p className={classes.normalText}>Students Learning</p>
        </Col>
        <Col className="my-3" lg="3" sm="6">
          <p className={classes.bigText}>80+</p>
          <p className={classes.normalText}>Cities</p>
        </Col>
        <Col className="my-3" lg="3" sm="6">
          <p className={classes.bigText}>10 Lakh+</p>
          <p className={classes.normalText}>Online Test Attempted</p>
        </Col>
      </Row>

      <Row className={classes.midSection}>
        <Col className={classes.midLeft} lg="6">
          <p className={classes.midMaintext}>
            Create your <span className={classes.mainColor}>own app,</span>
            <br />
            <span className={classes.mainColor}>Make your</span> own brand.
          </p>
          <p className={classes.numbers_mediumText}>
            No Commission. Direct Pay-outs
          </p>
          <p className={classes.numbers_smallText}>
            Get the android app and web portal by your brand's name
          </p>
          <button
            onClick={openModal}
            id="features"
            className={classes.createMyAppButton}
          >
            Create my own app
          </button>
        </Col>
        <Col lg="4">
          <img
            className={classes.girlPicImage}
            alt="Ingenium Education"
            src={girlPic}
          />
        </Col>
      </Row>

      <Row style={{ paddingTop: "20px" }} className={classes.midSection2}>
        <Col
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
          md="6"
        >
          <img
            className={classes.cubeImage}
            alt="Ingenium Education"
            src={cube}
          />
          <Tabcomponent />
        </Col>
        <Col
          className={classes.MobileWidth}
          md="6"
          style={{ textAlign: "left", alignSelf: "center" }}
        >
          {/* <p className={classes.smallFeatureText}>8 features in 1 app</p> */}
          <p className={classes.midMaintext2}>
            Choosing the{" "}
            <span className={classes.mainColor}>Best Features for</span>
            <br />
            Your Mobile App
          </p>
        </Col>
      </Row>

      <CustomModal show={showModal} hideModal={hideModal} />
    </>
  );
};

export default Numbers;
