import React, { useEffect, useState } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import first from "../../assets/images/testimonials/1.webp";
import second from "../../assets/images/testimonials/2.webp";
import third from "../../assets/images/testimonials/3.webp";
import forth from "../../assets/images/testimonials/4.webp";
import Mobile from "./Mobile";
import "./style.css";

const Owlcarousel = () => {
  const [classesSingle, setClassesSingle] = useState('singleMobile element1')
  const [classesDouble, setClassesDouble] = useState('doubleMobile element1')
  const [classes, setClasses] = useState('trippleMobile element1')
  
  useEffect(() => {
    console.log(window.screen.width);
  }, [window.screen.width]);

  useEffect(() => {
    const callbackFunc = (entries, observer) => {
      entries.forEach(entry => {
      var txt = entry.target.id + " visibility: " + entry.isIntersecting;
      
      if(entry.isIntersecting) {
        setClasses('trippleMobile popup')
        setClassesSingle('singleMobile popup')
        setClassesDouble('doubleMobile popup')
      }
    });
  }

    let options = {
        root: null,
        rootMargin: '0px',
        threshold: 0.3
      };

    let observer = new IntersectionObserver(callbackFunc, options);

    observer.observe(document.getElementById('element3'));
    observer.observe(document.getElementById('element2'));
    observer.observe(document.getElementById('element1'));
    // observer.observe(document.getElementById('secondBlock'));
  }, [])

  return (
    <>
    <div className={classesSingle} id='element1' style={{ overflow: "hidden", width: "85%", margin: "10px" }}>
      <OwlCarousel
        autoplay={3000}
        items={1}
        className="owl-theme"
        loop
        margin={1}
        nav
      >
        <div class="item">
          <Mobile
            image={first}
            name="Saurabh"
            client="Bio Cell"
            text="The best part is that I now have an app with my coaching name on it. There are no commissions and I get direct payments of my students. That is something that no other company does. Thanks Ingenium!"
          />
        </div>
        <div class="item">
          <Mobile
            client="Nuclear Zone"
            name="Raj Malik"
            image={second}
            text="The best part is that I now have an app with my coaching name on it. There are no commissions and I get direct payments of my students. That is something that no other company does. Thanks Ingenium!"
          />
        </div>
        <div class="item">
          <Mobile
            image={third}
            name="Sameer Prajapati"
            client="Testnote"
            text="The best part is that I now have an app with my coaching name on it. There are no commissions and I get direct payments of my students. That is something that no other company does. Thanks Ingenium!"
          />
        </div>
        <div class="item">
          <Mobile
            image={forth}
            name="Rahul Gautam"
            client="Lotus Commerce Academy"
            text="The best part is that I now have an app with my coaching name on it. There are no commissions and I get direct payments of my students. That is something that no other company does. Thanks Ingenium!"
          />
        </div>
      </OwlCarousel>
    </div>
    <div className={classesDouble} id='element2' style={{ overflow: "hidden", width: "85%", margin: "10px" }}>
      <OwlCarousel
        autoplay={3000}
        items={2}
        className="owl-theme"
        loop
        margin={1}
        nav
      >
        <div class="item">
          <Mobile
            image={first}
            name="Saurabh"
            client="Bio Cell"
            text="The best part is that I now have an app with my coaching name on it. There are no commissions and I get direct payments of my students. That is something that no other company does. Thanks Ingenium!"
          />
        </div>
        <div class="item">
          <Mobile
            client="Nuclear Zone"
            name="Raj Malik"
            image={second}
            text="The best part is that I now have an app with my coaching name on it. There are no commissions and I get direct payments of my students. That is something that no other company does. Thanks Ingenium!"
          />
        </div>
        <div class="item">
          <Mobile
            image={third}
            name="Sameer Prajapati"
            client="Testnote"
            text="The best part is that I now have an app with my coaching name on it. There are no commissions and I get direct payments of my students. That is something that no other company does. Thanks Ingenium!"
          />
        </div>
        <div class="item">
          <Mobile
            image={forth}
            name="Rahul Gautam"
            client="Lotus Commerce Academy"
            text="The best part is that I now have an app with my coaching name on it. There are no commissions and I get direct payments of my students. That is something that no other company does. Thanks Ingenium!"
          />
        </div>
      </OwlCarousel>
    </div>
    <div id='element3' className={classes} style={{ overflow: "hidden", width: "85%", margin: "10px" }}>
      <OwlCarousel
        autoplay={3000}
        items={3}
        className="owl-theme"
        loop
        margin={1}
        nav
      >
        <div class="item">
          <Mobile
            image={first}
            name="Saurabh"
            client="Bio Cell"
            text="The best part is that I now have an app with my coaching name on it. There are no commissions and I get direct payments of my students. That is something that no other company does. Thanks Ingenium!"
          />
        </div>
        <div class="item">
          <Mobile
            client="Nuclear Zone"
            name="Raj Malik"
            image={second}
            text="The best part is that I now have an app with my coaching name on it. There are no commissions and I get direct payments of my students. That is something that no other company does. Thanks Ingenium!"
          />
        </div>
        <div class="item">
          <Mobile
            image={third}
            name="Sameer Prajapati"
            client="Testnote"
            text="The best part is that I now have an app with my coaching name on it. There are no commissions and I get direct payments of my students. That is something that no other company does. Thanks Ingenium!"
          />
        </div>
        <div class="item">
          <Mobile
            image={forth}
            name="Rahul Gautam"
            client="Lotus Commerce Academy"
            text="The best part is that I now have an app with my coaching name on it. There are no commissions and I get direct payments of my students. That is something that no other company does. Thanks Ingenium!"
          />
        </div>
      </OwlCarousel>
    </div>
    </>
  );
};

export default Owlcarousel;

